export default {
    database_errors: [],
    verification_errors: [],
    output: null,
    processing: false,
    success: false,
    token: '',
    form: {
        host: '127.0.0.1',
        port: '3306',
        database: '',
        password: '',
        username: '',
        purchase_code: '',
    },

    install() {
        if (this.processing) return

        if (!this.validate()) return

        this.processing = true
        this.output = null

        this.checkDatabase()
    },

    validate() {
        this.database_errors = []
        this.verification_errors = []

        if (!this.form.host) {
            this.database_errors.push('database host required!')
        }
        if (!this.form.port) {
            this.database_errors.push('database port is required!')
        }
        if (!this.form.database) {
            this.database_errors.push('database name is required!')
        }
        if (!this.form.username) {
            this.database_errors.push('database username is required!')
        }

        if (!this.form.purchase_code) {
            this.verification_errors.push('purchase code is required!')
        }

        if (!this.form.host || !this.form.port || !this.form.database || !this.form.username || !this.form.purchase_code) {
            return false
        }

        return true
    },

    checkDatabase() {
        axios.post('/install/src/check-database.php', this.form)
            .then(response => {
                console.log('response checkDatabase!')
                console.log(response.data)

                if (!response.data.success && response.data.error) {
                    this.processing = false
                    this.database_errors.push(response.data.error)
                    return
                }

                if (response.data.success) {
                    this.verify()
                }
            })
            .catch(error => {
                console.log('error in checkDatabase!')
                console.log(error.response.data)
            })
    },

    verify() {
        this.form['domain'] = window.location.host
        this.form['url'] = window.location.href

        axios.post('https://spack-admin.codedott.com/api/verify-installation', this.form)
            .then(response => {
                console.log('response verify!')
                console.log(response.data)

                if (!response.data.success) {
                    this.processing = false
                    this.verification_errors.push(response.data.error)
                    return
                }

                if (response.data.success) {
                    this.token = response.data.token
                    this.download(response.data.token)
                }
            })
            .catch(error => {
                console.log('error in verify!')
                console.log(error.response.data)
                this.processing = false
            })
    },

    download(token) {
        axios.post('/install/src/download.php', {
            token
        })
            .then(response => {
                console.log('response download!')
                console.log(response.data)

                if (response.data.success) {
                    this.extract()
                } else {
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error')
                console.log( error.response.data )
                this.recordError(error.response.data)
            })
    },

    extract() {
        axios.post('/install/src/extract.php')
            .then(response => {
                console.log('response extract!')
                console.log(response.data)

                if (response.data.success) {
                    this.config()
                } else {
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error')
                console.log( error.response.data )
                this.recordError(error.response.data)
            })
    },

    config() {
        axios.post('/install/src/config.php', this.form)
            .then(response => {
                console.log('response config!')
                console.log(response.data)

                if (response.data.success) {
                    this.recipe()
                } else {
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error')
                console.log(error.response.data)
                this.recordError(error.response.data)
            })
    },

    recipe() {
        axios.post('/installation-recipe', { token: this.token })
            .then(response => {
                console.log('response recipe!')
                console.log(response.data)

                if (response.data.success) {
                    this.success = true
                } else {
                    this.recordError(response.data)
                }
            })
            .catch(error => {
                console.log('error in recipe!')
                console.log(error.response.data)
                this.recordError(error.response.data)
            })
    },

    recordError(payload) {
        axios.post('https://spack-admin.codedott.com/api/installation/status', {
            payload,
            token: this.token
        })
            .then(response => {
                console.log('response recordError!')
                console.log(response.data)
                this.processing = false
            })
            .catch(error => {
                console.log('error in recordError!')
                console.log(error.response.data)
                this.processing = false
            })
    },
}
